import * as API from '@/api/index';
export default {
    // 获取配置
    list: params => {
        return API.POST('api/growthValue/list', params)
    },
    // 修改配置
    add: params => {
        return API.POST('api/growthValue/add', params)
    },
}